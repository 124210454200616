import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {Provider} from "react-redux";
import store from "./stores";
import {HashRouter as Router} from "react-router-dom";
import UseCustomAPIAuth from "./hooks/UseCustomAPIAuth";
import {AuthProvider} from "./context/AuthContext";
import "./index.css";

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <AuthProvider type={UseCustomAPIAuth}>
        <App/>
      </AuthProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

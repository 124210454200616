import React from "react";
import { Route, Redirect } from "react-router-dom";
import { UseAuth } from "../hooks/UseAuth";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = UseAuth();
  return (
    <Route
      {...rest}
      render={props =>
        auth.user ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname:
                rest.routeProps && rest.routeProps.auth_redirect_path
                  ? rest.routeProps.auth_redirect_path
                  : "/auth/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

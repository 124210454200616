import React from 'react';
import CreateCandidateForm from '../components/forms/CreateCandidateForm';

export const downloadFile = url => {
  const downloadLink = document.createElement('a')
  downloadLink.setAttribute('href', url)
  downloadLink.setAttribute('target', '_blank')
  downloadLink.setAttribute('id', 'download-action')
  downloadLink.setAttribute('download', true)
  document.querySelector('body').insertAdjacentElement('afterbegin', downloadLink)
  downloadLink.click()
  document.getElementById('download-action').remove()
}

export function addParameterToURL(param, sorting = '') {
  if (param !== '') {
    let url = window.location.href.split('?')[0];
    url += (
      url.split('?')[1] ? '&' : ''
    ) + param;
    return `${url}${sorting.sortMode !== undefined ? sorting.sortMode : ''}`;
  }

  return window.location.href
}


export function buildUrl(filters, prepend = '?') {
  return `${prepend}${Object.entries(filters).reduce((acc, item) => {
    if (item[1] !== 'null' && item[1] !== null) {
      if (typeof item[1] === 'object') {
        acc += `${item[0]}=${encodeURIComponent(item[1].join(','))}&`
      } else {
        if (item[0] === 'status') {
          let ids = [];
          if (typeof item[1] === 'string') {
            ids = item[1].split(',').reduce((a, item) => {
              a.push(item)
              return a
            }, [])
          } else {
            ids = item[1].reduce((a, item) => {
              a.push(item.id)
              return a
            }, [])
          }
          acc += `status=${ids.join(',')}&`
        } else {
          acc += `${item[0]}=${encodeURIComponent(item[1])}&`
        }
      }
    }

    return acc
  }, '')}`;
}

export const ConfirmDelete = ({label, onCancel, onConfirm, description}) => {
  return(
    <>
      <header className="salmon">
        <svg width="94" height="96" viewBox="0 0 94 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="47.5" cy="52.5" r="39.5" fill="white"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M47.5001 56.8016L30.51 73.7917C29.3221 74.9796 27.3962 74.9796 26.2084 73.7917C25.0206 72.6039 25.0206 70.678 26.2084 69.4902L43.1985 52.5001L26.2084 35.51C25.0206 34.3221 25.0206 32.3962 26.2084 31.2084C27.3962 30.0206 29.3221 30.0206 30.51 31.2084L47.5001 48.1985L64.4902 31.2084C65.678 30.0206 67.6039 30.0206 68.7917 31.2084C69.9796 32.3962 69.9796 34.3221 68.7917 35.51L51.8016 52.5001L68.7917 69.4902C69.9796 70.678 69.9796 72.6039 68.7917 73.7917C67.6039 74.9796 65.678 74.9796 64.4902 73.7917L47.5001 56.8016Z" fill="#F33F3F"/>
        </svg>
      </header>
      <div className="content">
        <p className="title">{label}</p>
        {description && <p className='c-black'>{description}</p> }
        <div className="actions">
          <button className="white" onClick={onCancel}>Non</button>
          <button className="blue" onClick={onConfirm}>Oui</button>
        </div>
      </div>
    </>
  )
}

export function CreateCandidate({onDone, candidate, categories}) {
  return (
    <>
      <header>
        <h1 className="t-header">Ajouter un candidat</h1>
      </header>
      <div className="content">
        <CreateCandidateForm categories={categories} onDone={onDone} candidate={candidate}/>
      </div>
    </>
  )
}

function get_all_rights(role) {
  switch (role) {
    case 'REFERENT':
      return ['REFERENT']
    default:
      return []
  }
}

export function hasCap(role, user_role) {
  if (user_role) {
    if (user_role === 'ADMIN' || user_role === 'RH') {
      return true;
    } else {
      return role !== undefined ? get_all_rights(user_role).includes(role) : get_all_rights('REFERENT')
    }
  }

  return false
}

export function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}


import React  from 'react';
import Login from './Login';
import { NotFound } from '../../../components/shared/not-found/NotFound';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import ResetPassword from "./ResetPassword";

const AuthComponentRenderer = (props) => {

    const authComponents = {
        'login': Login,
        'register': Register,
        'forgot-password': ForgotPassword,
        'reset-password': ResetPassword,
        'notfound': NotFound
    };

    const FinalComponent = authComponents[props.tag] ? authComponents[props.tag ] : authComponents['notfound'];
       
    return <FinalComponent {...props} />

}

export default AuthComponentRenderer;
import apiService from '../../conf/api.custom';

export default {
  login: (values) => {
    apiService.post('auth/local', values).then(res => console.log(res))
  },
  register: (endpoint, values) => {
  },
  resetPassword: (endpoint, email) => {
  },
  validateToken: endpoint => {
  }
};

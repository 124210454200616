import React, { createContext, useState } from 'react';

export const ModalContext = createContext();

export function ModalProvider({ children }) {

  const [data, setData] = useState({
    display: false,
    data: {
      component: null
    }
  });

  return (
    <ModalContext.Provider value={
      [data, setData]
    }>
      {children}
    </ModalContext.Provider>
  );
}

import React, {useState} from "react";
import apiService from "../../../conf/api.custom";
import ResetPassFormAction from "../../../components/forms/ResetPassFormAction";
import {withRouter} from "react-router-dom";

const ResetPassword = props => {

    const [alert, setAlert] = useState();
    const params = new URLSearchParams(props.location.search);


    const submit = async (data) => {
        console.log(data)
        apiService
            .post('auth/reset-password', {
                code: params.get('code'), // code contained in the reset link of step 3.
                password: data.newpass,
                passwordConfirmation: data.newpassconfirm,
            })
            .then(response => {
                console.log("Your user's password has been reset.");
                setAlert(['success', 'Votre mot de passe a bien été mis à jour.']);

            })
            .catch(error => {
                setAlert(['error', error.data.error.message]);
            });
    };

    return <ResetPassFormAction onSubmit={submit} alert={alert}/>;
};

export default withRouter(ResetPassword);

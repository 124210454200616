import React from "react";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {ModalProvider} from './context/ModalContext';
import {SlidingModalProvider} from './context/SlidingModalContext';
import AppRouter from "./routing/AppRouter";
import "react-toastify/dist/ReactToastify.css";
import "./assets/style.scss";
import {fetchUser} from './stores/app/app.actions';
import {AppUserSelector} from './stores/app/app.selectors';

const App = props => {
  React.useEffect(() => {
    props.fetchUser()
  }, [props.location]);

  return (
    <>
      <SlidingModalProvider>
        <ModalProvider>
          <AppRouter />
        </ModalProvider>
      </SlidingModalProvider>
    </>
  );
};

export default withRouter(connect(
  state => ({
    user : AppUserSelector(state)
  }),
  {
    fetchUser
  }
)(App))

import React from 'react'

function Footer() {
    return (
        <footer className="mainfooter">
            <div className="wrapper">
                <em>Created w/ love by Emilien.</em>
            </div>
        </footer>
    )
}

export default Footer

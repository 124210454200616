import React from 'react'
import InputBoxDoneTyping from 'react-input-box-done-typing';
import apiService from '../../conf/api.custom';

const MultipleSelectSearch = ({value, onChange, collection}) => {

  const [results, setResults] = React.useState([]);
  const [selected, setSelected] = React.useState(value || []);
  const input = React.useRef();

  const search = value => {
    let url = `${collection}?`
    url += `populate=*&filters[name][$containsi]=${value}`
    if(value === '') {
      setResults([])
    } else {
      apiService.get(url).then(response => {
        const alreadySelected = selected.reduce((acc, item) => {
          acc.push(item.id)
          return acc
        }, [])
        if(response.data.data) {
          if(response.data.data.length > 0) {
            const sets = response.data.data.reduce((acc,item) =>{
              acc.push({
                id: item.id,
                name: item.attributes.name
              })
              return acc
            },[])
            setResults(sets.filter(user => !alreadySelected.includes(user.id)))
          } else {
            setResults(value)
          }

        }else {
          setResults(response.data.filter(user => !alreadySelected.includes(user.id)))
        }
      })
    }
  }

  const selectUser = user => {
    setSelected([
      ...selected,
      user
    ])
    setResults(() => [])
    input.current.querySelector('input').value = ''
    input.current.querySelector('input').focus()
  }

  const deleteSelected = user => {
    setSelected(selected.filter(s => s.id !== user.id))
    input.current.querySelector('input').focus()
  }

  React.useEffect(() => {
    onChange(selected.reduce((acc, item) => {
      acc.push(item.id)
      return acc
    }, []))
  }, [selected]);

  const newEntry = () => {
    apiService.post(collection, {
      data: {
        name: results
      }
    }).then(res => {
      setSelected([
        ...selected,
        {id: res.data.data.id, name: res.data.data.attributes.name}
      ])
      setResults(() => [])
      input.current.querySelector('input').value = ''
      input.current.querySelector('input').focus()
    })
  }

  return (
    <div className="multiple-select-search relative border-blue">
      <div className="search-field flex">
        {selected.length > 0 &&
        <div className="tags">
          {selected.map(user => <span key={user.id} className="tags__item">
            {user.name}
            <svg onClick={() => deleteSelected(user) } width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.6538 5.15385H14.6923V4.61538C14.6923 3.72467 13.9676 3 13.0769 3H10.9231C10.0324 3 9.30769 3.72467 9.30769 4.61538V5.15385H6.34615C5.60388 5.15385 5 5.75773 5 6.5V8.38462C5 8.68198 5.2411 8.92308 5.53846 8.92308H5.83273L6.29793 18.6922C6.33902 19.555 7.04777 20.2308 7.91146 20.2308H16.0885C16.9523 20.2308 17.661 19.555 17.7021 18.6922L18.1673 8.92308H18.4615C18.7589 8.92308 19 8.68198 19 8.38462V6.5C19 5.75773 18.3961 5.15385 17.6538 5.15385ZM10.3846 4.61538C10.3846 4.31849 10.6262 4.07692 10.9231 4.07692H13.0769C13.3738 4.07692 13.6154 4.31849 13.6154 4.61538V5.15385H10.3846V4.61538ZM6.07692 6.5C6.07692 6.35155 6.19771 6.23077 6.34615 6.23077H17.6538C17.8023 6.23077 17.9231 6.35155 17.9231 6.5V7.84615C17.7571 7.84615 6.76457 7.84615 6.07692 7.84615V6.5ZM16.6264 18.641C16.6127 18.9286 16.3764 19.1538 16.0885 19.1538H7.91146C7.62355 19.1538 7.3873 18.9286 7.37364 18.641L6.91087 8.92308H17.0891L16.6264 18.641Z" fill="#fff"/>
              <path d="M12.0004 18.0769C12.2977 18.0769 12.5388 17.8358 12.5388 17.5385V10.5385C12.5388 10.2411 12.2977 10 12.0004 10C11.703 10 11.4619 10.2411 11.4619 10.5385V17.5385C11.4619 17.8358 11.703 18.0769 12.0004 18.0769Z" fill="#fff"/>
              <path d="M14.6928 18.0769C14.9901 18.0769 15.2312 17.8358 15.2312 17.5385V10.5385C15.2312 10.2411 14.9901 10 14.6928 10C14.3954 10 14.1543 10.2411 14.1543 10.5385V17.5385C14.1543 17.8358 14.3954 18.0769 14.6928 18.0769Z" fill="#fff"/>
              <path d="M9.30799 18.0769C9.60536 18.0769 9.84645 17.8358 9.84645 17.5385V10.5385C9.84645 10.2411 9.60536 10 9.30799 10C9.01063 10 8.76953 10.2411 8.76953 10.5385V17.5385C8.76953 17.8358 9.01059 18.0769 9.30799 18.0769Z" fill="#fff"/>
            </svg>
          </span>)}
        </div>
        }
        <span className="flex flex-grow" ref={input}>
          <InputBoxDoneTyping
            style={{
              width: '100%'
            }}
            defaultValue=""
            doneTyping={search}
            placeholder="Tapez pour chercher..."
          />
        </span>
      </div>
      {typeof results !== 'object' && results.length > 0 && <div className="results flex flex-column">
        <strong style={{
          padding: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <small>{`${results} n'existe pas`}</small>
          <button type="button" onClick={newEntry} className="button button--full-outline">Ajouter</button>
        </strong>
      </div>}
      {typeof results === 'object' && results.length > 0 && <div className="results flex flex-column">
        {results.map(
          user => <span key={user.id} onClick={() => selectUser({name: user.name, id: user.id})}>{user.name}</span>
        )}
      </div>}
    </div>
  )
}

export default MultipleSelectSearch

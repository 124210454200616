import React, { createContext, useState } from 'react';

export const SlidingModalContext = createContext();

export function SlidingModalProvider({ children }) {

  const [data, setData] = useState({
    display: false,
    affected: false,
    consultantsSelected: []
  });

  React.useEffect(() => {
    if( !data.display ) {
      setData({
        ...data,
        consultantsSelected: [],
        affected: false
      })
    }
  }, [data.display]);


  return (
    <SlidingModalContext.Provider value={
      [data, setData]
    }>
      {children}
    </SlidingModalContext.Provider>
  );
}

import React from 'react'
import AuthComponentRenderer from './components/AuthComponentRenderer';
import { connect } from 'react-redux';
import { fetchUser } from '../../stores/actions'
import { Redirect } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext';

class Auth extends React.Component {

  constructor( props ) {
    super(props)
    this.state = {
        redirectToReferrer: false
    }
  }

  static contextType = AuthContext;

  componentDidMount(){
    if( this.context.user ) this.setState({ redirectToReferrer: true })
  }
  componentDidUpdate() {
    if( this.context.user ) {
      this.setState({ redirectToReferrer: true })
    }

  }

  render(){
    const { action }  = this.props.match.params;
    const { from } = this.props.location.state || { from: { pathname: "/" } };

    if ( this.state.redirectToReferrer ) return <Redirect to={from} />;

    return (
      <AuthComponentRenderer
        { ...this.props }
        tag={ action }
        authContext = { this.context }
        redirectToReferrer={ () => this.setState({ redirectToReferrer : true}) }
      />
    )
  }
}

export default connect(
  state => ({
    user: state.app.user
  }),
  { fetchUser }
)(Auth)

import axios from "axios";
import LocalStorage from '../helpers/LocalStorage';

const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

/**
 * Ajout automatique du token sur l'ensemble des requête effectué avec AXIOS
 */
apiService.interceptors.request.use(
  config => {
    // console.log(LocalStorage.get("user"))
    const token = LocalStorage.get("user") ? JSON.parse(LocalStorage.get("user")).jwt : null
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }


    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * Gestion des cas d'erreur renvoyé par l'API
 */
apiService.interceptors.response.use(
  response => {
    console.log(response)
    return response;
  },
  error => {
    if (error.status) {
      switch (error.response.status) {
        case 401:
          break;
        case 403:
          break;
        case 404:
          break;
        default:
          break;
      }
    }

    return Promise.reject(error.response);
  }
);

export default apiService;

import { createSelector } from 'reselect';

export const appSelector = state => state.app;

export const appIsLoadingSelector = createSelector(
    [ appSelector ],
    app => app.isLoading
)

export const AppUserSelector = createSelector(
    [appSelector],
    app => app.user
)
import React, {useState} from "react";
import ResetPassForm from "../../../components/forms/ResetPassForm";
import apiService from "../../../conf/api.custom";

const ForgotPassword = () => {

    const [alert, setAlert] = useState();


    const submit = async (data) => {
        console.log(data)
        apiService.post('auth/forgot-password', data)
            .then(response => {
                const message = `Please check your email to reset your password.`;
                setAlert(['success', message]);
            })
            .catch(()=> setAlert(['error', 'Une erreur est survenue']))
    };

    return <ResetPassForm onSubmit={submit} alert={alert}/>;
};

export default ForgotPassword;

import Auth from "../features/auth";
import LazyCandidateDetail from '../features/candidate/LazyCandidateDetail';
import LazyRefFreelance from '../features/freelance/LazyRefFreelance';
import LazyPosts from "../features/posts/Lazy";
import LazyAdminUsers from "../features/admin/users/LazyAdminUsers";
import LazyAdminRejectionMails from "../features/admin/rejectionMail/LazyAdminRejectionMails";

export const routes = [
  {
    path: "/",
    private: true,
    component: LazyPosts,
    exact: true
  },
  {
    path: "/admin/users",
    private: true,
    component: LazyAdminUsers,
    exact: true
  },
  {
    path: "/admin/rejection-mails",
    private: true,
    component: LazyAdminRejectionMails,
    exact: true,
  },
  {
    path: "/recruitment/candidates/list",
    private: true,
    component: LazyPosts,
    exact: true,
    routeProps: {
      main_nav: true,
      main_nav_label: "Recrutement",
      main_nav_link: "/recruitment/candidates/list"
    }
  },
  {
    path: "/referentiel/externe",
    private: true,
    component: LazyRefFreelance,
    exact: true,
    routeProps: {
      main_nav_label: "Freelances",
      main_nav_link: "/referentiel/externe"
    }
  },
  {
    path: "/recruitment/candidates/detail/:id",
    private: true,
    component: LazyCandidateDetail,
    exact: true
  },
  {
    path: "/auth/:action",
    private: false,
    component: Auth,
    exact: true
  }
];

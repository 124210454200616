import React from 'react'
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";

const LoginForm = ({onSubmit, error, alert}) => {
  const { register, handleSubmit, formState: {errors}} = useForm();

  const submit = data => {
    onSubmit(data)
  }
  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    }} className="max-w-xl container mx-auto p-8 rounded bg-white">
      <form onSubmit={handleSubmit(submit)} className="">
        {error !== '' && <p  style={{
          background: '#fff',
          marginBottom: 36,
          borderRadius: 4
        }} className="text-center text-red-600 font-black">{error}</p>}
        <div className="row-form mb-6">
          <div className="flex flex-col w-full">
            <label className="mb-3" htmlFor="lastName">Email</label>
            <input className="bg-gray-100" id="Nom" defaultValue="" {...register("identifier", {required: true})} />
            {errors.identifier && <span className="error">Champs obligatoire</span>}
          </div>
        </div>

        <div className="row-form mb-6">
          <div className="flex flex-col w-full">
            <label className="mb-3" htmlFor="lastName">Mot de passe</label>
            <input type="password" className="bg-gray-100" {...register("password", {required: true})} />
            {errors.password && <span className="error">Champs obligatoire</span>}
          </div>
        </div>

        <Link to='/auth/forgot-password' className="text-center t-base-small c-blue" style={{marginBottom: 16, display: 'block'}}>Mot de passe oublié?</Link>

        <div className="row-form justify-center">
          <button className="button button--blue button--medium button--rounded" type="submit">Valider</button>
        </div>
    </form>
    </div>
  );
}

export default LoginForm

import React from 'react';
import Loadable from 'react-loadable';
import { AppLoader } from '../../../components/utils/Loading';

const LazyAdminRejectionMails = Loadable({
  loader: () => import('./index'),
  loading: () => <AppLoader title='data' />
})

export default LazyAdminRejectionMails;

import moment from 'moment';
import React from "react";
import {Switch, Route} from "react-router-dom";
import SlidingModal from '../components/shared/SlidingModal';
import Modal from '../components/ui/Modal';
import {ModalContext} from '../context/ModalContext';
import {SlidingModalContext} from '../context/SlidingModalContext';
import {UseAuth} from '../hooks/UseAuth';
import {routes} from "./routes";
import {PrivateRoute} from "./PrivateRoute";
import ScrollToTop from "./ScrollToTop";
import {Header} from "../components";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {AppUserSelector} from "../stores/selectors";
import {NotFound} from "../components/shared/not-found/NotFound";

const AppRouter = props => {
  const [slidingModal, setSlidingModal] = React.useContext(SlidingModalContext)
  const [dataModal, setDataModal] = React.useContext(ModalContext)

  const auth = UseAuth()

  return (
    <ScrollToTop>
      <SlidingModal
        data={slidingModal}
        onClose={() => setSlidingModal({...slidingModal, display: false, component: null})}
      />
      {dataModal.display && <Modal data={dataModal} onClose={()=>setDataModal({...dataModal, display: false, component:null})} />}
      <Header/>
      <main className="mb-6">
        <Switch>
          {
            routes.map(route => {
              return route.private ? (
                <PrivateRoute
                  user={props.user}
                  key={route.path}
                  component={route.component}
                  path={route.path}
                  exact
                  {...route}
                />
              ) : (
                 <Route
                   key={route.path}
                   render={props => <route.component {...props} {...route} />}
                   path={route.path}
                   exact={route.exact}
                 />
              );
            })}
          <Route component={NotFound}/>
        </Switch>
      </main>
      {auth.user &&
      <p className="text-gray-600 font-light text-center mb-4">&copy; CosaVotra {moment().format('YYYY')}</p>
      }
    </ScrollToTop>
  );
};

export default withRouter(
  connect(state => (
    {
      user: AppUserSelector(state)
    }
  ))(AppRouter)
);

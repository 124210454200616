import apiService from "../../conf/api.custom";
import LocalStorage from '../../helpers/LocalStorage';

export const REQUEST_USER = 'get user';
export const FETCH_USER_SUCCESS = 'fetch user success';
export const FETCH_USER_ERROR = 'fetch user error';
export const RESET_USER = 'RESET_USER';


export const requestUser = () => (
  {
    type: REQUEST_USER
  }
);

export const fetchUserSucess = user => (
  {
    type: FETCH_USER_SUCCESS,
    user
  }
);

export const fetchUser = () => dispatch => {
  dispatch(requestUser());
  return apiService.get('users/me').then(
    user => dispatch(fetchUserSucess(user.data)),
    error => {
      if(LocalStorage.get('user')) {
        LocalStorage.remove('user')
        window.location.replace('/auth/login')
      }
      dispatch(fetchUserError(error.response))
    }
  )
}

export const resetUser = () => dispatch => {
  dispatch(fetchUserSucess(null));
}

export const fetchUserError = error => (
  {
    type: FETCH_USER_ERROR,
    error
  }
);


class LocalStorage {

    store = ( key, values ) => {
        window.localStorage.setItem(key, values)
    }

    remove = (key) => {
        window.localStorage.removeItem(key)
    }

    get = (key) => {
        return window.localStorage.getItem(key);
    }
}


const LocalStorageInstance = new LocalStorage();

export default LocalStorageInstance;
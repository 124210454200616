import React from 'react'
import Loadable from 'react-loadable';
import { AppLoader } from '../../components/utils/Loading';

const LazyCandidateDetail = Loadable({
  loader: () => import(/* webpackChunkName: 'candidate-detail' */ './index'),
  loading: () => <AppLoader title='data' />
})

export default LazyCandidateDetail;

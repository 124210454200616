import React from 'react'
import { useForm, Controller } from "react-hook-form";
import apiService from '../../conf/api.custom';
import { ModalContext } from '../../context/ModalContext';
import { ConfirmDelete, debounce } from '../../helpers/functions';
import MultipleSelectSearch from '../shared/MultipleSelectSearch';
import UiSelect from '../shared/UiSelect';
import UploadArea from '../shared/UploadArea';

const getDefaultValues = candidate => {
  return {
    Nom: candidate.attributes.Nom,
    Prenom: candidate.attributes.Prenom,
    phone: candidate.attributes.phone,
    Email: candidate.attributes.Email,
    source_other: candidate.attributes.source_other,
    recruiter: candidate.attributes.recruiter.data && { key: candidate.attributes.recruiter.data.id },
    post_name: candidate.attributes.post_name,
    linkedin_profile: candidate.attributes['linkedin_profile'],
    category: candidate.attributes.category.data && candidate.attributes.category.data.attributes,
    source: candidate.attributes.source.data && candidate.attributes.source.data.attributes,
    contract: candidate.attributes.contract.data && candidate.attributes.contract.data.attributes,
    office: candidate.attributes.office.data && candidate.attributes.office.data.attributes,
    coment: candidate.attributes['coment'],
    cv: candidate.attributes.cv.data && candidate.attributes.cv.data.id,
    post_description: candidate.attributes.post_description.data && candidate.attributes.post_description.data.id,
    expertise: candidate.attributes.expertise.data.reduce((acc, item) => {
      acc.push({
        id: item.id,
        name: item.attributes.name
      })
      return acc
    }, []),
    referents: candidate.attributes.referents.data.reduce((acc, item) => {
      acc.push({
        id: item.id,
        name: item.attributes.name
      })
      return acc
    }, [])
  }
}

const CreateCandidateForm = ({ onDone, candidate }) => {

  const [modal, setModal] = React.useContext(ModalContext);

  const { watch, control, register, handleSubmit, setError, formState: { errors } } = useForm({
    defaultValues: candidate ? getDefaultValues(candidate) : {}
  });

  const source = watch("source");

  const deleteCandidate = () => {
    setModal({
      ...modal,
      display: true,
      component: <ConfirmDelete
        label="Êtes-vous sur de vouloir supprimer ce candidat?"
        onCancel={() => setModal({ ...modal, display: false, component: null })}
        onConfirm={() => {
          setModal({ ...modal, display: false, component: null })
          apiService.delete(`candidates/${candidate.id}`).then(onDone)
        }}
      />
    })
  }

  const onSubmit = async data => {
    await uploadMedia(data);

    if (candidate) {
      editCandidate(candidate, data)
    } else {
      addCandidate(data)
    }
  }

  function addCandidate(data) {
    apiService
      .post('candidates', {
        data: {
          ...data,
          source: data.source.id,
          fullName: `${data.Prenom} ${data.Nom}`,
          status: process.env.REACT_APP_DEFAULT_CANDIDATE_STATUS,
        }
      }
      )
      .then(onDone)
  }

  function editCandidate(candidate, data) {
    apiService
      .put(`/candidates/${candidate.id}`, {
        data: {
          ...data,
          source: data.source.id,
          fullName: `${data.Prenom} ${data.Nom}`,
          cv: data.cv || (
            candidate.attributes.cv.data ? candidate.attributes.cv.data.id : null
          ),
          post_description: data.post_description || (
            candidate.attributes.post_description.data ? candidate.attributes.post_description.data.id : null
          ),
        }
      })
      .then(onDone);
  }

  async function uploadMedia(data) {
    const formData = new FormData();

    [data.cv, data.post_description]
      .forEach(
        filesItem => {
          filesItem &&
            Array.prototype.forEach.call(
              filesItem,
              function (file) {
                formData.append('files', file);
              }
            );
        }
      );

    // If formData files entry isn't empty proceed to upload and wait
    formData.get('files') &&
      await apiService
        .post(
          '/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        .then(res => {
          const isCVUploaded = data.cv !== null;
          const isPostDescriptionUploaded = data.post_description !== null;

          if (isCVUploaded && isPostDescriptionUploaded) {
            data.cv = res.data[0].id;
            data.post_description = res.data[1].id;
          } else if (isCVUploaded) {
            data.cv = res.data[0].id;
          } else if (isPostDescriptionUploaded) {
            data.post_description = res.data[0].id;
          }
        });

    return data;
  }

  const checkKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const testEmailUniquness = () => debounce(
    async value => {
      const toTriggerCheck = candidate ? candidate.attributes.Email !== value : true;
      toTriggerCheck &&
        await apiService
          .get(`candidates?filters[email][$eq]=${value}&fields[0]=email&pagination[pageSize]`)
          .then(res => Array.isArray(res.data.data) &&
            res.data.data.length > 0 &&
            setError(
              'Email',
              {
                type: 'validate',
              }
            )
          )
    },
    500
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
      <div className="row-form">
        <div className="row-form__line">
          <label htmlFor="lastName">Nom</label>
          <input className="border-blue" id="Nom" {...register("Nom", { required: true })} />
          {errors.Nom && <span className="error">Champs obligatoire</span>}
        </div>
        <div className="row-form__line">
          <label htmlFor="lastName">Prénom</label>
          <input className="border-blue" id="lastName" {...register("Prenom", { required: true })} />
          {errors.Prenom && <span className="error">Champs obligatoire</span>}
        </div>

        <div className="row-form__line">
          <label htmlFor="lastName">Profil</label>
          <Controller
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <UiSelect
                onChange={onChange}
                value={value}
                options={() => apiService.get('categories')}
                fullW
              />
            )}
            name="category"
          />
          {errors.category && <span className="error">Champs obligatoire</span>}
        </div>

        <div className="row-form__line">
          <label htmlFor="lastName">Nom du poste</label>
          <input className="border-blue" id="lastName" {...register("post_name", { required: true })} />
          {errors.post_name && <span className="error">Champs obligatoire</span>}
        </div>
      </div>

      <div className="row-form">
        <div className="row-form__line">
          <label htmlFor="lastName">Email</label>
          <input
            type='email'
            className="border-blue"
            {...register(
              "Email",
              {
                required: true,
                pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                validate: testEmailUniquness(),
              }
            )}
          />
          {errors.Email && errors.Email.type === 'required' && <span className="error">Champs obligatoire</span>}
          {errors.Email && errors.Email.type === 'pattern' && <span className="error">Format non valid</span>}
          {errors.Email && errors.Email.type === 'validate' && <span className="error">Ce candidat est déjà enregistré dans la base</span>}
        </div>

        <div className="row-form__line">
          <label htmlFor="lastName">Source</label>
          <Controller
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <UiSelect
                onChange={onChange}
                value={value}
                options={() => apiService.get('sources')}
                fullW
              />
            )}
            name="source"
          />
          {errors.source && <span className="error">Champs obligatoire</span>}
        </div>
        {source && source.key === 'autres' &&
          <div className="row-form__line">
            <label htmlFor="lastName">Précisez la source</label>
            <input className="border-blue" {...register("source_other", { required: source.key === 'autres' })} />
            {errors.source_other && <span className="error">Champs obligatoire</span>}
          </div>
        }

        <div className="row-form__line fullW">
          <label htmlFor="lastName">Téléphone</label>
          <input className="border-blue" {...register("phone", { required: false })} />
          {errors.phone && <span className="error">Champs obligatoire</span>}
        </div>

        <div className="row-form__line fullW">
          <label htmlFor="lastName">Profil Linked In</label>
          <input className="border-blue" {...register("linkedin_profile", { required: false })} />
          {errors.linkedin_profile && <span className="error">Champs obligatoire</span>}
        </div>
      </div>


      <div className="row-form">
        <div className="row-form__line">
          <label htmlFor="lastName">Contrat</label>
          <Controller
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <UiSelect
                onChange={onChange}
                value={value}
                options={() => apiService.get('contracts')}
                fullW
              />
            )}
            name="contract"
          />
          {errors.contract && <span className="error">Champs obligatoire</span>}
        </div>

        <div className="row-form__line">
          <label htmlFor="lastName">Bureau</label>
          <Controller
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <UiSelect
                onChange={onChange}
                value={value}
                options={() => apiService.get('offices')}
                fullW
              />
            )}
            name="office"
          />
          {errors.office && <span className="error">Champs obligatoire</span>}
        </div>
      </div>

      <div className="row-form">
        <div className="row-form__line fullW">
          <label htmlFor="lastName">Expertise(s)</label>
          <Controller
            control={control}
            rules={{
              required: false
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <MultipleSelectSearch
                onChange={onChange}
                collection="expertises"
                value={value}
                fullW
                style={{
                  marginRight: 16
                }}
              />
            )}
            name="expertise"
          />
          {errors.expertise && <span className="error">Champs obligatoire</span>}
        </div>
      </div>

      <div className="row-form">
        <div className="row-form__line fullW">
          <label htmlFor="lastName">Responsable(s)</label>
          <Controller
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <MultipleSelectSearch
                onChange={onChange}
                collection="users"
                value={value}
                fullW
                style={{
                  marginRight: 16
                }}
              />
            )}
            name="referents"
          />
          {errors.referents && <span className="error">Champs obligatoire</span>}
        </div>
      </div>



      <div className="row-form">
        <div className="row-form__line fullW">
          <label htmlFor="lastName">Chargé de recrutement</label>
          <Controller
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <UiSelect
                formatUser
                onChange={onChange}
                value={value}
                options={() => apiService.get('users?filters[capability][$eq]=RH')}
                fullW
              />
            )}
            name="recruiter"
          />
          {errors.recruiter && <span className="error">Champs obligatoire</span>}
        </div>
      </div>

      <div className="row-form">
        <div className="row-form__line fullW">
          <label htmlFor="lastName">Cv</label>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <UploadArea
                value={value}
                onChange={onChange}
                fullW
                style={{
                  marginRight: 16
                }}
              />
            )}
            name="cv"
          />
          {errors.cv && <span className="error">Champs obligatoire</span>}
        </div>
      </div>

      <div className="row-form">
        <div className="row-form__line fullW">
          <label htmlFor="lastName">
            Description de poste
            &nbsp;(
            {(candidate &&
              candidate.attributes.post_description.data &&
              candidate.attributes.post_description.data.attributes.url) ?
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_BACK_URL}${candidate.attributes.post_description.data.attributes.url}`}
              >Cliquer pour voir le doc uploadé</a> :
              <>Aucun doc a été uploadé</>
            }
            )
          </label>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <UploadArea
                value={value}
                onChange={onChange}
                fullW
                style={{
                  marginRight: 16
                }}
              />
            )}
            name="post_description"
          />
          {errors.post_description && <span className="error">Champs obligatoire</span>}
        </div>
      </div>

      <div className="row-form">
        <div className="row-form__line fullW">
          <label htmlFor="lastName">Commentaire sur le candidat</label>
          <textarea className="border-blue" {...register("coment")} />
          {errors.coment && <span className="error">Champs obligatoire</span>}
        </div>
      </div>

      <div className="row-form justify-end mt-4" style={{
        position: 'absolute',
        top: 12,
        right: 48
      }}>
        {candidate &&
          <button onClick={deleteCandidate} style={{ color: 'red', marginRight: 8 }} className="button button--full-outline"
            type="button">Supprimer</button>
        }
        <button
          className="button button--blue button--medium button--rounded"
          type="submit"
        >Valider</button>
      </div>
    </form>
  );
}

export default CreateCandidateForm

import React from 'react'
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import cx from "classnames/bind";

const ResetPassFormAction = ({onSubmit, error, alert}) => {
  const { register, handleSubmit, formState: {errors}} = useForm();

  const submit = data => {
    onSubmit(data)
  }
  return (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    }} className="max-w-xl container mx-auto p-8 rounded bg-white">
      {alert && (
          <div className={cx({
            "bg-green": alert[0] === "success",
            "bg-red": alert[0] !== "success",
            "c-white rounded shadow-md p-2": true
          })}>
            <div dangerouslySetInnerHTML={{ __html: alert[1] }} />
          </div>
      )}
      <form onSubmit={handleSubmit(submit)} className="">
        {error !== '' && <p  style={{
          background: '#fff',
          marginBottom: 36,
          borderRadius: 4
        }} className="text-center text-red-600 font-black">{error}</p>}

        <div className="row-form mb-6">
          <div className="flex flex-col w-full">
            <label className="mb-3" htmlFor="lastName">Nouveau mot de passe</label>
            <input type="password" className="bg-gray-100" id="Nom" defaultValue="" {...register("newpass", {required: true})} />
            {errors.newpass && <span className="error">Champs obligatoire</span>}
          </div>
        </div>

        <div className="row-form mb-6">
          <div className="flex flex-col w-full">
            <label className="mb-3" htmlFor="lastName">Confirmation nouveau mot de passe</label>
            <input  type="password" className="bg-gray-100" id="Nom" defaultValue="" {...register("newpassconfirm", {required: true})} />
            {errors.newpassconfirm && <span className="error">Champs obligatoire</span>}
          </div>
        </div>

        <Link to='/auth/login' className="text-center t-base-small c-blue" style={{marginBottom: 16, display: 'block'}}>Connexion</Link>

        <div className="row-form justify-center">
          <button className="button button--blue button--medium button--rounded" type="submit">Valider</button>
        </div>
    </form>
    </div>
  );
}

export default ResetPassFormAction

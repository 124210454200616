import React from 'react'
import Loadable from 'react-loadable';
import { AppLoader } from '../../components/utils/Loading';

const LazyPosts = Loadable({
  loader: () => import(/* webpackChunkName: 'candidates' */ './index'),
  loading: () => <AppLoader title='data' />
})

export default LazyPosts;

import React from "react";
import UserService from "../api/services/UserService";
import apiService from "../conf/api.custom";
import LocalStorage from '../helpers/LocalStorage';

function UseCustomAPIAuth() {
  const [user, setUser] = React.useState(LocalStorage.get("user") ? JSON.parse(LocalStorage.get("user")) : null  );

  const signin = async (values) => {
    return apiService
      .post('auth/local', values)
      .then(res => res.data)
      .catch(error => error.data)
  };

  const signup = async values => {
    return await UserService.register("users", values);
  };

  const signout = () => {
    setUser(null);
    LocalStorage.remove('user')
  };

  const sendPasswordResetEmail = async email => {
    return await UserService.resetPassword("users", email);
  };

  const confirmPasswordReset = values => {};

  React.useEffect(() => {
    if(user !== null && !LocalStorage.get("user")){
      LocalStorage.store('user', JSON.stringify(user))
    }
  }, [user]);

  // Return the user object and auth methods
  return {
    user,
    setUser,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset
  };
}

export default UseCustomAPIAuth;

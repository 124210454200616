import React from 'react'
import Loadable from 'react-loadable';
import { AppLoader } from '../../components/utils/Loading';

const LazyRefFreelance = Loadable({
  loader: () => import(/* webpackChunkName: 'home' */ './index'),
  loading: () => <AppLoader title='home' />
})

export default LazyRefFreelance;

import cx from 'classnames/bind';
import React from 'react'

const UiSelect = ({value, onChange, style, options, fullW, fieldName, formatUser}) => {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [selected, setSelected] = React.useState(value);

    const formatData = response => {
        if (response) {
            if (formatUser !== undefined) {
                setData(
                    response.data.reduce((acc, item) => {
                        acc.push({
                            text: item.name,
                            key: item.id,
                            id: item.id
                        })
                        return acc
                    }, [])
                )
            } else {
                setData(
                    response.data.data.reduce((acc, item) => {
                        acc.push({
                            text: fieldName !== undefined ? item.attributes[fieldName] : item.attributes['name'],
                            key: item.attributes['key'],
                            id: item.id
                        })
                        return acc
                    }, [])
                )
            }
        }
    }

    React.useEffect(() => {
        if (typeof options === 'function') {
            options().then(response => formatData(response))
        } else {
            setData(options)
            setSelected(value ? value : null)
        }
    }, []);

    React.useEffect(() => {
        if (data && data.length > 0 && value) {
            console.log('DEBUG', value)
            setSelected(data.find(item => item.key === value.key))
        }
    }, [data, value]);

    React.useEffect(() => {
        onChange(selected)
    }, [selected]);

    return (
        data &&
        <span className="flex--horizontal-space-b flex--vertical-space-c" style={{
            ...style,
            background: '#fff',
            color: '#00B5D4'
        }}>
      <span className="flex--vertical-space-c">
        <span style={{
            width: fullW ? '100%' : 'initial',
            minWidth: '175px'
        }} className="bg-white ui-select flex items-center font-black" onClick={() => setOpen(!open)}>
          {selected ? <span style={{marginRight: 8}} className={cx({selected})}>{selected.text}</span> :
              <span>Choisir</span>}
            <i>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.7072 9.29304L12.0001 3.58594L6.29297 9.29304L7.70718 10.7073L12.0001 6.41436L16.293 10.7073L17.7072 9.29304Z"
                    fill="#00B5D4"/>
                <path
                    d="M7.70718 13.293L12.0001 17.5859L16.293 13.293L17.7072 14.7073L12.0001 20.4144L6.29297 14.7073L7.70718 13.293Z"
                    fill="#00B5D4"/>
              </svg>
            </i>
            {
                open &&
                <div className="choices flex flex-col">
                    {
                        data.map((choice) => <span key={choice.key} onClick={() => setSelected(choice)}>
                  {choice.text}
                </span>)
                    }
                </div>
            }
        </span>
      </span>
    </span>
    )
}

export default UiSelect

import {candidateService} from '../../../api';
export const REQUEST_POSTS = 'REQUEST_POSTS';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_ERROR = 'FETCH_POSTS_ERROR';

export const requestPosts = () => (
  {
    type: REQUEST_POSTS
  }
);

export const fetchPostsSucess = posts => (
  {
    type: FETCH_POSTS_SUCCESS,
    posts
  }
);

export const fetchPostsError = error => (
  {
    type: FETCH_POSTS_ERROR,
    error
  }
);

export const resetPosts = () => async dispatch => {
  dispatch(fetchPostsSucess([]));
};

export const fetchPosts = filters => async dispatch => {
  dispatch(requestPosts())
  return candidateService
    .fetch(`candidates?populate=*${filters}&sort=publishedAt:desc`)
    .then(response => dispatch(fetchPostsSucess(response.data)))
};

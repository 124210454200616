import React from 'react'
import Loadable from 'react-loadable';
import { AppLoader } from '../../../components/utils/Loading';

const LazyAdminUsers = Loadable({
  loader: () => import(/* webpackChunkName: 'admin-user' */ './index'),
  loading: () => <AppLoader title='data' />
})

export default LazyAdminUsers;

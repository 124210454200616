import React from 'react'

const UploadArea = ({onChange}) => {
  const fileInput = React.useRef();
  const [files, setFiles] = React.useState(null);

  const selectFile = () => {
    if(fileInput.current) {
      fileInput.current.click()
    }
  }

  const changeFile = e => {
    setFiles(e.target.files)
  }

  React.useEffect(() => {
    console.log('FILES', files)
    onChange(files)
  }, [files]);


  return (
    <div className="upload-area border-blue">
      <input onChange={changeFile} name={"files"} className="hidden" type="file" ref={fileInput} accept=".pdf" />
      <button onClick={selectFile} type="button" data-dashlane-rid="05a4abee75853d76" data-dashlane-label="true" data-form-type="other">
        <svg width="30px" height="24px" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="sc-fWCJzd sc-dvQaRk iQcEdS cKErPx">
          <path
            d="M21.525 2.394H7.814v1.582h13.711c.47 0 .824.498.824 1.091v9.834l-2.785-3.165c-.2-.233-.453-.42-.738-.549a2.21 2.21 0 00-.91-.194 2.209 2.209 0 00-.91.19 2.126 2.126 0 00-.739.545l-2.925 3.378-2.967-3.354a2.143 2.143 0 00-.739-.543 2.226 2.226 0 00-.909-.193c-.315.002-.626.07-.911.2-.285.13-.536.318-.737.552l-2.925 3.425V7.637H2.505v9.874c.023.153.059.304.107.451v.127l.042.095c.056.142.125.28.206.411l.099.15c.074.106.157.207.247.301l.123.119c.13.118.274.221.429.308h.025c.358.214.772.327 1.195.325h16.481a2.47 2.47 0 001.037-.239c.322-.153.605-.375.826-.648.222-.263.392-.564.502-.886.067-.216.108-.439.124-.664V5.067a2.493 2.493 0 00-.65-1.805 2.7 2.7 0 00-1.773-.868z"
            fill="#32324D"/>
          <path
            d="M12.526 9.18c.91 0 1.648-.708 1.648-1.582 0-.874-.738-1.582-1.648-1.582-.91 0-1.648.708-1.648 1.582 0 .874.738 1.582 1.648 1.582zM3.297 6.395h.823V3.988h2.473v-.79-.792H4.12V0h-.823c-.219 0-.849.002-.825 0v2.406H0v1.582h2.472v2.407h.825z"
            fill="#32324D"/>
        </svg>
        <span>
          {(files === null || files === '') ? 'Cliquez ici pour selectionner votre fichier' : <span>
            {files[0].name}
            <small onClick={e => {
              e.stopPropagation()
              fileInput.current.value = ''
              fileInput.current.dispatchEvent(new Event('change'))
              setFiles(null)
            }} className="block mt-3 text-red-600">Retirer</small>
          </span>}
        </span>
      </button>
    </div>
  )
}

export default UploadArea

import React from "react";
import {withRouter} from "react-router-dom";
import LoginForm from '../../../components/forms/LoginForm';
import {UseAuth} from "../../../hooks/UseAuth";

export const Login = () => {
  const auth = UseAuth();

  const [error, setError] = React.useState('');

  const submit = async (data) => {
    const login = await auth.signin(data);
    if(login.data !== null) {
      auth.setUser(login)
    } else {
      setError(login.error.message)
    }
  };

  return (
    <LoginForm onSubmit={submit} error={error} />
  );
};

export default withRouter(Login);

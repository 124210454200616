import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import cx from "classnames/bind";
import { Link } from "react-router-dom";
import { UseAuth } from "../../../hooks/UseAuth";

const ComposantErreur = props => {
  return (
    <div className="error-message">
      <span>{props.children}</span>
    </div>
  );
};

const ComposantInput = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <div className={cx("form-group", { invalid: errors[field.name] })}>
      <label> {props.label} </label>
      <input type="text" {...props} className="form-control" {...field} />
    </div>
  );
};

const Register = () => {
  const auth = UseAuth();
  const submit = (values, actions) => {
    auth.signup(values);
  };

  const userSchema = Yup.object().shape({
    email: Yup.string()
      .required("Champs obligatoire")
      .email("L'email doit être valide"),
    password: Yup.string().required("Champs obligatoire")
  });

  return (
    <Formik
      onSubmit={submit}
      initialValues={{ email: "", password: "" }}
      validationSchema={userSchema}
      validateOnChange={true}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          onSubmit={handleSubmit}
          className="w-50 bg-white border p-5 d-flex flex-column"
        >
          <Field name="email" label="Email" component={ComposantInput} />
          <ErrorMessage name="email" component={ComposantErreur} />
          <Field
            name="password"
            type="password"
            label="Mot de passe"
            component={ComposantInput}
          />
          <ErrorMessage name="password" component={ComposantErreur} />
          <Field
            name="password2"
            type="password"
            label="Confirmation mot de passe"
            component={ComposantInput}
          />
          <ErrorMessage name="password" component={ComposantErreur} />
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitting}
          >
            Créer un compte
          </button>
          <span className="form_link text-center">
            <Link to="/auth/login">Connexion</Link>
          </span>
        </form>
      )}
    </Formik>
  );
};

export default Register;
